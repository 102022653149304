import React from "react"
import styled from "styled-components"

import autoPolicyUpdatesDesktopPNG from "src/images/home/auto_policy_updates_desktop.png"
import autoPolicyUpdatesDesktopWEBP from "src/images/home/auto_policy_updates_desktop.webp"
import autoPolicyUpdatesMobilePNG from "src/images/home/auto_policy_updates_mobile.png"
import autoPolicyUpdatesMobileWEBP from "src/images/home/auto_policy_updates_mobile.webp"
import { openNewTab } from "src/utils/openNewTab"

import { Row, Col, Img } from "./style"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import { Title, SubTitle } from "src/componentsV2/sections/Text"
import { css } from "@emotion/core"
import { BookDemoButton } from "src/componentsV2/ui/Button"
import config from "src/config"

const AutoPolicyUpdatesContainer = styled(ContainerPage)`
  padding-top: 110px;
  @media (min-width: 768px) {
    padding-top: 160px;
    padding-bottom: 150px;
  }
`

const index = () => {
  return (
    <AutoPolicyUpdatesContainer data-loadable-component="auto-policy-updates-hero">
      <WrapperPage>
        <Row className="items-center">
          <Col className="description">
            <Title className="title">
              Keeping your handbook policies updated automatically
            </Title>
            <SubTitle className="px-4 md:px-0 mb-5 md:mb-5">
              AirMason can now not just create your handbook, but can keep your policies updated at all times.
            </SubTitle>
            <SubTitle className="px-4 md:px-0 mb-5 md:mb-5">
              Leveraging AI, but always overseen and reviewed by HR and legal experts.
            </SubTitle>
            <SubTitle className="px-4 md:px-0 mb-5 md:mb-10">
              <div className="text-orange-2 font-bold">
                Saving you the time and hassle of having to constantly keep track and update policies and employee handbooks manually.
              </div>
            </SubTitle>
            <div className="hidden md:block">
              <BookDemoButton
                id="book-demo-button"
                onClick={() => openNewTab(config.CALENDLY.AUTOMATED_POLICY_UPDATES)}
                css={css`
                font-size: 16px;
                padding: 25px 38px;
                width: 210px;
                border-radius: 100px;
                `}
                title="Book a demo"
                />
                </div>
          </Col>
          <Col className="text-center md:text-right">
            <picture>
              <source srcSet={autoPolicyUpdatesDesktopWEBP} type="image/webp" media="(min-width: 769px)" />
              <source srcSet={autoPolicyUpdatesDesktopPNG} type="image/jpg" media="(min-width: 769px)" />
              <source srcSet={autoPolicyUpdatesMobileWEBP} type="image/webp" />
              <source srcSet={autoPolicyUpdatesMobilePNG} type="image/jpg" />
              <Img src={autoPolicyUpdatesDesktopPNG} width="600" height="412" alt='handbook apu' />
            </picture>
          </Col>
        </Row>
      </WrapperPage>
    </AutoPolicyUpdatesContainer>
  )
}

export default index
